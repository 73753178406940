<template>
	<div class="container">
		<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
			<el-table-column prop="name" label="分类" width="180" align="center"></el-table-column>
			<el-table-column label="标签" align="center">
				<template #default="scope">
					<el-tag class="tag-item" v-for="(item,index) in scope.row.tags" :key="index">{{item.name}}<i
							class="el-icon-close" @click="del(item)"></i></el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="180" align="center">
				<template #default="scope">
					<el-button type="text" icon="el-icon-edit" @click="handle(scope.row)">新增</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog title="新增标签" v-model="dialogVisible" width="30%">
			<el-form ref="form" v-model="add_query" label-width="70px">
				<el-form-item label="名称">
					<el-input v-model="add_query.name"  maxlength="5"  show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="排序">
					<el-input-number v-model="add_query.sort" :min="1" :max="100000" label="排序值"></el-input-number>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="add">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		props: ['type', 'activeType'],
		data() {
			return {
				loading: false,
				add_query: {
					name: '',
					cat_id: '',
					sort: 1
				},
				tableData: [],
				dialogVisible: false
			}
		},
		watch: {
			dialogVisible(val) {
				if(!val) {
					this.add_query = {
						name: '',
						cat_id: '',
						sort: 1
					}
				}
				
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getList() {
				this.loading = true;
				this.$axios({
					url: 'admin/tag/sysLists',
					method: 'get',
					params: {
						type: this.type
					}
				}).then((res) => {
					this.loading = false;
					if (res.code == 200) {
						this.tableData = res.data
					}
				}).catch(err => {
					this.loading = false;
					console.log(err)
				});
			},
			handle(item) {
				this.dialogVisible = true
				this.add_query.cat_id = item.id
			},
			add() {
				this.$axios({
					url: 'admin/tag/create',
					method: 'post',
					data: {
						...this.add_query
					}
				}).then((res) => {
					if (res.code == 200) {

						this.dialogVisible = false
						this.getList()
						this.$message({
							type: 'success',
							message: '添加成功!'
						});
					}
				}).catch(err => {
					console.log(err)
				});
			},
			del(item) {
				console.log(item)
				this.$confirm('此操作将永久删除该标签, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.delTag(item)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			delTag(item) {
				this.$axios({
					url: 'admin/tag/delSys',
					method: 'delete',
					params: {
						ids: item.id
					}
				}).then((res) => {
					this.loading = false;
					if (res.code == 200) {
						this.getList()
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err)
				});
			}
		}
	}
</script>

<style scoped>
	.tag-item {
		margin: 0 5px;
	}

	.tag-item .el-icon-close {
		display: none;
	}

	.tag-item:hover .el-icon-close {
		display: inline-block;
	}
</style>
